import React from 'react';
import Story from '../Story';
import "./404.css"
import pea from '../../assets/pea.jpg';
interface NotFoundProps {
  stories: string[];
  maxWidth: number;
}

const NotFound: React.FC<NotFoundProps> = ({ stories, maxWidth }) => {
    const randIdx = Math.floor(Math.random() * stories.length);
    var randStory = stories[randIdx];

  return (
  <>
  <div className="story" style={{maxWidth: maxWidth, margin: 'auto'}}> 
    <Story text={stories} />
  </div>
  <div style={{display: 'flex', justifyContent: 'center'}}>
    <img src={pea} style={{ width: '50%', height: '50%'}}/>
  </div>
  </>
  );
};


export default NotFound;
