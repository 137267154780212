import React, {FC} from "react";
import {List, ListProps} from "../List";

import "./Page.css";

interface SectionProps {
  title: string;
  description?: string;
  items: listitem[];
  type: "work" | "normal";
  link?: linkinfo
}

type listitem  = {
    href?: string;
    description: string
  }

interface linkprops  {
    linkinfo: linkinfo
}

type linkinfo  = {
    href: string;
    label: string;
}

const Link: FC<linkprops> = ({ linkinfo }) => (
    <>
    <a className="appstoreLink" href={linkinfo.href} target="_blank">{linkinfo.label}</a>
    </>

)

const Section: FC<SectionProps> = ({ title, description, items, type, link}) => (

  <div>
    {type === "work" && link !== undefined && (
    <>
     <h2>{title}</h2>
     <p>{description}</p>
     <List items={items} maxWidth={500}></List>
     <Link linkinfo={link}/>
     <br></br>
     <br></br>
     </>

     )}
 {type === "normal" && (
    <>
     <h3>{title}</h3>
     <p>{description}</p>
     <List items={items} maxWidth={500}></List>
     </>
     )}
   
  </div>
);


interface PageProps {
  title: string;
  sections?: SectionProps[];
}

const Page: FC<PageProps> = ({ title, sections }) => (
  <div>
    <body>
    <h1>{title}</h1>
    {sections && sections.map((section, index) => (
      <Section key={index} title={section.title} description={section.description}items={section.items} type={section.type} link={section.link} />
    ))}
    </body> 
  </div>
);

export {Page};
export type {SectionProps};
export {Section};
