import React from 'react';
import robot from '../../assets/robot.png';
import abstract from '../assets/abstract.png';
import ThreeDModel from '../ThreeDModel';


const OtherStuff: React.FC = () => {
  return (
<div className="header">
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}> 
  <div> 
    <img
      src={robot}
      alt="robot image"
      style={{ width: '90%', height: '90%', margin: 'auto', objectFit: 'contain'}}
    />
     {/* <ThreeDModel /> */}
  </div>
  {/* <div>
    <img
      src={abstract}
      alt="robot image"
      style={{ width: '70%', height: '70%', marginLeft:100, objectFit: 'contain'}}
    />
  </div>   */}
</div>
</div>
  );
};

export default OtherStuff;
