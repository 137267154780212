import React from 'react';

type StoryProps = {
  text: string[];
}

function Story(props: StoryProps) {
  const paragraphs = props.text.map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));

  return <div>{paragraphs}</div>;
}

export default Story;