import React from 'react';
import logo from './logo.svg';
import './Work.css';
import {List, ListProps} from '../Reusables/List';
import {Page, Section, SectionProps} from '../Reusables/Page/Page';

function Work() {



const awayLink = "https://apps.apple.com/us/app/awaybusiness/id1597170104"
const soapboxLink = "https://apps.apple.com/us/app/soapbox-talk-together/id1660144810"
const spectacoinsLink = "https://spectacoins.vercel.app/"
const letterboxdcloneLink = "https://github.com/jackderemiah/betterlox"
  const awayitems = [
    {
      description: "Developed an iOS app that tracks employee productivity in the workplace."
    }
  ]


const soapboxitems = [
  {
  description: "Created an audio based social networking iOS app." }]
const spectacoinsitems = [
  {
   description: "Developed a cryptocurrency tracking web app using NextJS with a Supabase backend."
  }
  ]

  const letterboxdcloneitems = [
    {description: "Created a letterboxd clone iOS app"}
  ]
  const sections: SectionProps[] = [
    {
      title: "Soapbox", items: soapboxitems, type: "work", link: {href:soapboxLink, label: "Download on the App Store"}
    },
    {
      title: "Away", items: awayitems, type: "work", link: {href:awayLink, label: "Download on the App Store"}
    },
    {
      title: "Spectacoins", items: spectacoinsitems, type: "work", link: {href:spectacoinsLink, label: "Try it out"}
    },
    {
      title: "Letterboxd clone", items: letterboxdcloneitems, type: "work", link:{href:letterboxdcloneLink, label: "Github repo"}
    }

  ]

  return (

    <div>
     <Page title='' sections={sections}/>
    </div>
    // <div className="header">
    //     <div className="title">

    //     <body>
    //     <div className="chunk"> 
    //     <br></br>
    //     <h2>Soapbox</h2>
        
    //     <List items={soapboxitems} maxWidth={500} /> 

    //     <a href={soapboxLink} className="appstoreLink" target={"_blank"}>Download on the App Store</a> 
       

    //     </div>
    //     <div className="chunk"> 
    //     <br></br>
    //     <h2>Away</h2>
    //     <List items={awayitems} maxWidth={500} />
    //     <a href={awayLink} className="appstoreLink" target={"_blank"}>Download on the App Store</a>
    //     <br></br>
    //     </div>

    //     <div className="chunk"> 
    //     <br></br>
    //     <h2>Spectacoins</h2>
    //     <List items={spectacoinsitems} maxWidth={500} />

        
    //     <a href={spectacoinsLink} className="appstoreLink" target={"_blank"}>Try it out</a>
    //     <br></br>
    //     </div>
    //     </body>
    //     <p style={{opacity: 0.0}}>Currently living in San Francisco. Here are a few bullets that will serve as my bio for now:</p>
    //     </div>
        
      
    // </div>
  );
}

export default Work;
