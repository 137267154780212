import React, { useState, useEffect } from "react";
import "./Navbar.css";

const NavBar: React.FC = () => {
  const [activeItem, setActiveItem] = useState("");
  const handleClick = (item: string) => {
    setActiveItem(item);
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      setActiveItem("home");
    } else if (path === "/work") {
      setActiveItem("work");
    } else if (path === "/stuff") {
      setActiveItem("stuff");
    } else if (path === "/test") {
      setActiveItem("test");
    }
  }, []);

  return (
    <div className="navbar">
      <nav>
        <ul className="nav-links">
          <li className={activeItem === "home" ? "active" : ""}>
            <a href="/" onClick={() => handleClick("home")}>
              Home
            </a>
          </li>
          <li className={activeItem === "work" ? "active" : ""}>
            <a href="/work" onClick={() => handleClick("work")}>
              Work
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;