import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import './Components/Home/Home'
import Home from './Components/Home/Home';
import Work from './Components/Work/Work';
import OtherStuff from './Components/Other Stuff/OtherStuff';
import NotFound from './Components/404/404';
import Layout from './Layout';
import {stories} from './Components/404/stories';
import {Page, Section, SectionProps} from './Components/Reusables/Page/Page';
import Test from './Components/Test';

const App: React.FC = () => {

  return (

    <Router>
      <Layout>
       <Routes>
       <Route  path="/" element={<Home/>}/>
       <Route  path="/work" element={<Work/>}/>
       <Route  path="/stuff" element={<OtherStuff/>}/>
       <Route path="/test" element={<Test/>}/>
       <Route path="*" element={<NotFound stories={stories} maxWidth={700}/>}/>
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;