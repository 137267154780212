import React from 'react';
import logo from './logo.svg';
import './Home.css';
import {List, ListProps} from '../Reusables/List';
import {Page, Section, SectionProps} from '../Reusables/Page/Page';


function Home() {

    const bioitems = 
    [
        {
            description: "2023 - Started working as an iOS engineer at Wells Fargo."
        },
        {
        description: "2022 - Created iOS social media app Soapbox."
        },
        {
            description: "2022 - Finished undergrad at SFSU with B.S in computer science."
        },
        {
            description: "2020 - Started working at AWAY Business developing an iOS app."
        },
        {
            description: "2018 - Started undergrad at SFSU studying computer science."
        }
    ]

    const contactitems = [
        {
            href:"https://github.com/jackderemiah",
            description:"Github"
        },
        {
            href:"https://www.linkedin.com/in/jack-deremiah-705b601bb/",
            description:"LinkedIn"
        }
        //, {
        //     href:"/resume.pdf",
        //     description:"Resume"
        // }
    ]
    const sections: SectionProps[] = 
    [
        {
        title: "About me", description: "Here are a few bullets that will serve as my bio for now:", items: bioitems, type: "normal"
        },
        {
        title: "Contact", items: contactitems, type: "normal"
        },
        {
        title: "Misc", items:  [{
            href:"/stuff",
            description:"Art"
        }], type: "normal"
        }

    ]
        
        return (
        <div>
        <Page
          title="Jack Deremiah"
          sections={sections}
        />
      </div>
      );

    //   return (
   
//     <div className="header">
//         <div className="title">
//         <body>
//         <div className="chunk"> 
//         <h1>Jack Deremiah</h1>
//         <h3>About me</h3>
//         <p>Currently living in San Francisco. Here are a few bullets that will serve as my bio for now:</p>
//         <List items={bioitems} maxWidth={500} />
//         </div>
//         <div className="chunk"> 
//         </div>
//         <h3>Contact</h3>
//             <ul>
//                 <li>jackderemiah@duck.com</li>
//                 <li><a href="https://github.com/jackderemiah" target="_blank">Github</a></li>
//                 <li><a href="https://www.linkedin.com/in/jack-deremiah-705b601bb/" target="_blank">LinkedIn</a></li>
//                 <li><a href="https://drive.google.com/file/d/1_CBGwnLmc-mzPjt05F_LEcxJqFTBbbWT/view" target="_blank">Resume</a></li>
//             </ul>
//         <h3>Misc</h3>
//         <ul>
//             <li><a href="/stuff"> art </a></li>

//         </ul>
       
//         </body>
//         </div>
        
      
//     </div>
//   );
}

export default Home;
