import React from 'react';
import NavBar from './Components/Navbar/Navbar';

interface LayoutProps {
    children: React.ReactNode;
  }

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <NavBar />
      <main>
        {children}
      </main>
    </div>
  );
};

export default Layout;
