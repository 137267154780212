import React from 'react';

interface ListProps {
  items: listitem[];
  maxWidth: number;
}

type listitem  = {
  href?: string;
  description: string
}
interface listitemprops {
  listitem: listitem
}
const ListItem: React.FC<listitemprops> = ({ listitem }) => {
  if (listitem.href !== undefined && listitem.href.startsWith("/")) {
  return (
  <>
  <li>
  
  <a href={listitem.href} >{listitem.description}</a>
  </li>
  </>
  );
  } else if (listitem.href !== undefined) {
    return (
      <>
      <li>
      
      <a href={listitem.href} target="_blank">{listitem.description}</a>
      </li>
      </>
      );
  } 
  else {
  return (
  <>
  <li>{listitem.description}</li>
  </>
  );
  }
  };
  
  const List: React.FC<ListProps> = ({ items, maxWidth }) => {
  return (
  <ul style={{ maxWidth }}>
  {items.map((item, index) => (
  <ListItem listitem={item} />
  ))}
  </ul>
  );
  };
export { List };  export type { ListProps }; 

